import { css } from "@emotion/react"
import React from "react"
import { flexBoxCss } from "../../../styles"
import AnnouncementContainerSection from "./AnnouncementContainerSection"
import StoreInformationContainerSection from "./StoreInformationContainerSection"

const container = css`
  width: 984px;
  margin-top: 70px;
  margin-bottom: 80px;
  height: 205px;
  ${flexBoxCss("h", "space-between", "flex-start")}

  @media (max-width: 1024px) {
    width: 100%;
    height: 330px;
    padding: 0px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
`

const AnnouncementSection = () => {
  return (
    <article css={container}>
      <AnnouncementContainerSection />
      <StoreInformationContainerSection />
    </article>
  )
}

export default AnnouncementSection
