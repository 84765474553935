import { css } from "@emotion/react"
import { navigate } from "gatsby-link"
import React, { useContext } from "react"
import { globalContext } from "../../../contexts/GlobalContext"
import { flexBoxCss } from "../../../styles"
import { Category } from "../../../types"
import { PAGE } from "../../../values"
import CategoryItem from "../../molecule/CategoryItem"
import DoubleTitle from "../../molecule/DoubleTitle"

const container = css`
  width: 100vw;
  background-color: #fafafa;
  padding: 60px 0;

  @media (max-width: 1024px) {
    padding: 30px 0;
    align-self: center;
  }
`

const categoryGrid = css`
  width: 984px;
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(8, 0fr);
  column-gap: 24px;
  row-gap: 40px;
  justify-content: center;

  @media (max-width: 800px) {
    row-gap: 5px;
    grid-template-columns: repeat(6, 0fr);
    column-gap: calc((100vw - 480px) / 7);
    width: 100vw;
    margin-top: 30px;
    max-width: 100vw;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(4, 0fr);
    column-gap: calc((100vw - 320px) / 5);
    margin-top: 30px;
    max-width: 100vw;
  }

  @media (max-width: 540px) {
    grid-template-columns: repeat(4, 0fr);
    column-gap: 0px;
    row-gap: 15px;
    width: 100vw;
    margin-top: 30px;
    max-width: 100vw;
  }
`

interface CategorySectionProps {
  data: Category[]
}

const CategorySection = ({ data }: CategorySectionProps) => {
  const context = useContext(globalContext)

  function handleCategoryClick(item: Category) {
    context?.categoryProducts.setCategory(item)
    navigate(PAGE.CATEGORY_PRODUCT)
  }

  return (
    <article css={[flexBoxCss("v", "center", "center"), container]}>
      <DoubleTitle />
      <div css={categoryGrid}>
        {data &&
          data.map(item => (
            <CategoryItem
              category={item}
              isSelected={false}
              key={item.id}
              onClick={() => handleCategoryClick(item)}
            />
          ))}
      </div>
    </article>
  )
}

export default CategorySection
