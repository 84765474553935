import { css } from "@emotion/react"
import React from "react"
import { getCategoryImage } from "../../../functions"
import { flexBoxCss, vBox } from "../../../styles"
import { Category } from "../../../types"
import CategoryTitle from "../CategoryTitle"

interface SectionTitleAndBackgroundProps {
  category: Category
  backgroundColor: string
  isCollapsed: boolean
  onCategoryClick: () => void
}

const rootStyle = css`
  ${flexBoxCss("h", "center")}
  height: 228px;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 40px;
  width: 100%;
  z-index: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    height: auto;
    position: sticky;
    z-index: 2;
    top: 87px;
    padding: 20px 5px;
    padding-bottom: 25px;
    width: 100vw;
  }

  #category-title-tab-container {
    ${flexBoxCss("h", "space-between", "center")}
    width: 984px;
    align-self: baseline;

    #category-see-more-button {
      font-family: 나눔고딕;
      font-size: 13px;
      font-weight: 700;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 2px 8px;
      border-radius: 10px;
      min-width: 66px;
      cursor: pointer;
      z-index: 5;
      box-shadow: 0px 2px 7px rgb(0, 0, 0, 0.2);
    }
  }
`

const collapsedRootStyle = css`
  ${flexBoxCss("h", "center")}
  height: 228px;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 40px;
  width: 100%;
  z-index: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    height: auto;
    position: sticky;
    z-index: 2;
    top: 87px;
    padding: 4px;
    padding-right: 8px;
    width: 100vw;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  }

  #category-title-tab-container {
    ${flexBoxCss("h", "space-between", "center")}
    width: 984px;
    align-self: baseline;

    #category-see-more-button {
      font-family: 나눔고딕;
      font-size: 13px;
      font-weight: 700;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 2px 8px;
      border-radius: 10px;
      min-width: 66px;
      cursor: pointer;
      z-index: 5;
      box-shadow: 0px 2px 7px rgb(0, 0, 0, 0.2);
    }
  }
`

const SectionTitleAndBackground = ({
  category,
  backgroundColor,
  isCollapsed,
  onCategoryClick,
}: SectionTitleAndBackgroundProps) => {
  function handleMoreButtonClick() {}

  return (
    <div
      css={isCollapsed ? collapsedRootStyle : rootStyle}
      style={{
        backgroundColor: category.color !== null ? "#" + category.color : backgroundColor,
      }}
    >
      <div id="category-title-tab-container">
        <CategoryTitle category={category} isCollapsed={isCollapsed} />
        <span
          id="category-see-more-button"
          onClick={() => {
            onCategoryClick()
          }}
        >
          더보기 {">"}
        </span>
      </div>
    </div>
  )
}

export default SectionTitleAndBackground
