import { API, graphqlOperation } from 'aws-amplify';

import { handleError, handleSuccess } from "..";
import { CreateBannerInput, CreateBannerMutation, DeleteBannerInput, DeleteBannerMutation, GetBannerQuery, ListBannersQuery, ModelBannerConditionInput, ModelBannerFilterInput, UpdateBannerInput, UpdateBannerMutation } from "../../API";

import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

import { Banner } from '../../types';

export const apiCreateBanner = async (input: CreateBannerInput, condition?: ModelBannerConditionInput) => {
  try {
    const response = await API.graphql(graphqlOperation(mutations.createBanner, { input, condition })) as { data: CreateBannerMutation };
    handleSuccess(response);
    return response.data.createBanner as unknown as Banner;
  } catch (error) {
    handleError(error);
  }
}

export const apiUpdateBanner = async (input: UpdateBannerInput, condition?: ModelBannerConditionInput) => {
  try {
    const response = await API.graphql(graphqlOperation(mutations.updateBanner, { input, condition })) as { data: UpdateBannerMutation };
    handleSuccess(response);
    return response.data.updateBanner as unknown as Banner;
  } catch (error) {
    handleError(error);
  }
}

export const apiDeleteBanner = async (input: DeleteBannerInput, condition?: ModelBannerConditionInput) => {
  try {
    const response = await API.graphql(graphqlOperation(mutations.deleteBanner, { input, condition })) as { data: DeleteBannerMutation }
    handleSuccess(response);
    return response.data.deleteBanner as unknown as Banner;
    return 
  } catch (error) {
    handleError(error);
  }
}

export const apiGetBanner = async (id: String) => {
  try {
    const response = await API.graphql(graphqlOperation(queries.getBanner, { id })) as { data: GetBannerQuery };
    handleSuccess(response);
    return response.data.getBanner as unknown as Banner;
  } catch (error) {
    handleError(error);
  }
}

export const apiListBanners = async (filter?: ModelBannerFilterInput, limit?: Number, nextToken?: String) => {
  try {
    const response = await API.graphql(graphqlOperation(queries.listBanners, { filter, limit, nextToken })) as { data: ListBannersQuery };
    handleSuccess(response);
    return response.data.listBanners?.items as unknown as Banner[];
  } catch (error) {
    handleError(error);
  }
}