import { css } from "@emotion/react"
import React, { CSSProperties } from "react"
import { flexBoxCss } from "../../../styles"
import PageTitleText from "../../atom/PageTitleText"

const container = css`
  ${flexBoxCss("h", "space-between", "center")}
  max-width: 984px;
  width: 100%;
`

const separator = css`
  flex: 1;
  height: 1px;
  background-color: black;
`

const titleContainer = css`
  ${flexBoxCss("v", "center", "center")}
  margin-left: 13px;
  margin-right: 13px;
`

const subtitleText = css`
  font-size: 12px;
  font-family: "Noto Sans KR";
  margin-bottom: 3px;
`

interface DoubleTitleProps {
  style?: CSSProperties
}

const DoubleTitle = ({ style }: DoubleTitleProps) => {
  return (
    <article css={container} style={style}>
      <div css={separator} />
      <div css={titleContainer}>
        <span css={subtitleText}>모든 제품을 한번에!</span>
        <PageTitleText title="카테고리별 상품" />
      </div>
      <div css={separator} />
    </article>
  )
}

export default DoubleTitle
