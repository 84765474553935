import { css } from "@emotion/react"
import React, { useContext } from "react"
import { PickedCategory } from "../../../contexts/IndexContext/hooks/useCategory"
import { flexBoxCss } from "../../../styles"
import ProductItem from "../../molecule/ProductItem"
import SectionTitleAndBackground from "../../molecule/SectionTitleAndBackground"
import Button from "@material-ui/core/Button"
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded"
import { navigate } from "gatsby"
import { PAGE } from "../../../values"
import { Category } from "../../../types"
import { globalContext } from "../../../contexts/GlobalContext"

interface CategorySectionProps {
  pickedCategory: PickedCategory
  backgroundColor: string
  nowScrollSectionNumber: number
  index: number
}

const root = css`
  align-self: center;
  width: 100%;
  position: relative;

  @media (max-width: 1024px) {
    margin-bottom: 25px;
  }

  #product-container {
    width: 984px;
    display: grid;
    grid-template-columns: repeat(4, 0fr);
    column-gap: calc((100% - ${230 * 4}px) / 3);
    justify-content: center;
    row-gap: 30px;
    padding-top: 117px;
    margin-bottom: 25px;

    @media (max-width: 1024px) {
      width: auto;
      row-gap: 0px;
      padding-top: 0px;
      margin-bottom: 5px;
      grid-template-columns: repeat(3, 0fr);
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(2, 0fr);
      justify-content: stretch;
    }
  }
`

const CategoryProductSection = ({ 
  pickedCategory, 
  backgroundColor,
  nowScrollSectionNumber,
  index
}: CategorySectionProps) => {
  const context = useContext(globalContext)

  function handleCategoryClick(item: Category) {
    context?.categoryProducts.setCategory(item)
    navigate(PAGE.CATEGORY_PRODUCT)
  }

  return (
    <article css={[flexBoxCss("v", "center", "center"), root]}>
      <SectionTitleAndBackground
        category={pickedCategory.category}
        backgroundColor={backgroundColor}
        isCollapsed={nowScrollSectionNumber===index+1}
        onCategoryClick={() => handleCategoryClick(pickedCategory.category)}
      />
      <div 
        id="product-container"
        className="sectionTab">
        {pickedCategory.products.map(item => (
          <ProductItem product={item} key={item.id} />
        ))}
      </div>
    </article>
  )
}

export default CategoryProductSection
