import { css } from "@emotion/react"
import React, { CSSProperties } from "react"

const container = css`
  display: flex;
  align-items: center;

  div {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #4eba1f;

    img {
      width: 20px;
      height: 20px;
      margin: 0;
    }
  }

  span {
    margin-left: 10px;
    font-family: "Noto Sans KR";
    font-size: 12px;
  }
`
interface OrderAvailableBadgeProps {
  style: CSSProperties
  isAvailable: boolean
}

const OrderAvailableBadge = ({ style, isAvailable }: OrderAvailableBadgeProps) => {
  return (
    <article css={container} style={style}>
      <div>
        <img src={require("../../../images/checkmark.svg")} />
      </div>
      <span style={{marginBottom: 2}}>현재 주문이 가능합니다</span>
    </article>
  )
}

export default OrderAvailableBadge
