import React, { useContext } from "react"
import { navigate } from "gatsby"
import PageTitleText from "../../../atom/PageTitleText"
import ArrowButton from "../../../atom/ArrowButton"
import { PAGE, SEE_EVENT_PRODUCT_BUTTON } from "../../../../values"
import { css } from "@emotion/react"
import AnnouncementItem from "../../../molecule/AnnouncementItem"
import { globalContext } from "../../../../contexts/GlobalContext"

const container = css`
  width: 40%;
  height: 100%;

  @media (max-width: 1024px) {
    align-self: center;
    width: 100%;
    padding-top: 40px;
  }
`
const divider = css`
  width: 100%;
  height: 3px;
  background-color: #d4d4d4;
  margin-top: 15px;
`

const announcementDivider = css`
  width: 100%;
  height: 1px;
  background-color: #d4d4d4;
`

const AnnouncementContainerSection = () => {
  const announcementHook = useContext(globalContext)?.announcementHook

  return (
    <article css={container}>
      <div className="h-box-spaced" style={{ alignItems: "flex-end" }}>
        <PageTitleText title={"공지사항"} isNotBold />
        <ArrowButton
          name={SEE_EVENT_PRODUCT_BUTTON}
          arrowDirection="RIGHT"
          onClick={() => navigate(PAGE.ANNOUNCEMENT)}
          isRed
        />
      </div>
      <div css={divider} />
      {announcementHook?.data &&
        announcementHook?.data.slice(0, 5).map((a, index) => (
          <>
            <AnnouncementItem
              announcement={a}
              isSelected={false}
              onClick={() => navigate(PAGE.ANNOUNCEMENT)}
              key={index.toString()}
            />
            {index + 1 !== announcementHook.data.length && <div css={announcementDivider} />}
          </>
        ))}
    </article>
  )
}

export default AnnouncementContainerSection
