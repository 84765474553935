import React, { useEffect, useState } from "react"
import { apiListBanners } from "../../../../functions/api/banner"
import { Banner } from "../../../../types"

export interface ImageGalleryItem {
  original: string
}

const useBanner = () => {
  const [banners, setBanners] = useState<ImageGalleryItem[]>([])
  const [mobileBanners, setMobileBanners] = useState<ImageGalleryItem[]>([])
  const [index, setIndex] = useState<number>(0)

  useEffect(() => {
    ;(async () => {
      const response = await apiListBanners()

      if (response) {
        let result = [],
          mobileResult = []
        for (let i = 0; i < response.length; i++) {
          result.push({
            original: response[i].uri,
          })
          mobileResult.push({
            original: response[i].mobileUri,
          })
        }
        setBanners(result)
        setMobileBanners(mobileResult)
      }
    })()
  }, [])

  function nextBanner() {
    if (index === banners.length - 1) setIndex(0)
    else setIndex(index + 1)
  }

  function prevBanner() {
    if (index === 0) setIndex(banners.length - 1)
    else setIndex(index - 1)
  }

  return {
    data: banners,
    mobileData: mobileBanners,
    nextBanner,
    prevBanner,
    index,
    selectedBanner: banners[index],
  }
}

export default useBanner
