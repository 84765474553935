import { css } from "@emotion/react"
import { navigate } from "gatsby"
import moment from "moment"
import React, { useContext } from "react"
import { globalContext } from "../../../contexts/GlobalContext"
import useEventProduct from "../../../contexts/IndexContext/hooks/useEventProduct"
import { PAGE, SEE_EVENT_PRODUCT_BUTTON } from "../../../values"
import ArrowButton from "../../atom/ArrowButton"
import PageTitleText from "../../atom/PageTitleText"
import EventProduct from "../../molecule/EventProduct"
import SingleLineAnnouncement from "../../molecule/SingleLineAnnouncement"
const styles = require("../../../pages/style.module.scss")
import Button from "@material-ui/core/Button"
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded"

interface EventProductSectionProps {
  style?: React.CSSProperties
}

const container = css`
  max-width: 984px;
  display: grid;
  grid-template-columns: repeat(4, 0fr);
  row-gap: 50px;
  column-gap: 24px;
  margin-bottom: 100px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 0fr);
    column-gap: 0px;
    margin-bottom: 45px;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 0fr);
    row-gap: 30px;
    margin-bottom: 45px;
  }
`

const eventTitle = css`
  width: 984px;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 50px;
  margin-bottom: 40px;
  @media (max-width: 1024px) {
    width: 100vw;
    padding-left: 20px;
    padding-top: 20px;
    margin-bottom: 20px;
  }
`

const backgroundImage = css`
  left: 0;
  width: 100%;
  height: 280px;
  position: absolute;
  background-color: #fff4e5;
  z-index: -2;
  padding-left: 112px;
  padding-right: 112px;
  padding-bottom: 90px;
  overflow-y: hidden;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    height: 140px;
    padding-left: 37px;
    padding-right: 37px;
    justify-content: center;
  }
`

const saleImage = css`
  width: 245px;
  height: 245px;
  position: relative;
  top: -54px;
  @media (max-width: 1024px) {
    height: 245px;
    width: 245px;
  }
`

const saleImage2 = css`
  width: 245px;
  height: 245px;
  position: relative;
  top: -54px;
  @media (max-width: 1024px) {
    display: none;
  }
`

const EventProductSection = ({ style }: EventProductSectionProps) => {
  const eventHook = useContext(globalContext)?.eventHook
  const storeHour = useContext(globalContext)?.storeHourHook
  const eventProducts = useEventProduct(true)

  const isEventActivated = eventHook?.getIsEventActivated()
  const eventTermText = eventHook?.getEventTermText()


  return (
    isEventActivated ? (
      <article className="v-box" style={{ ...style, alignItems: "center" }}>
        <div css={backgroundImage}>
          <img css={saleImage} src={require("../../../images/event-background-tile.svg")} />
          <img css={saleImage2} src={require("../../../images/event-background-tile.svg")} />
          <img css={saleImage2} src={require("../../../images/event-background-tile.svg")} />
        </div>
        <div className="h-box" css={eventTitle}>
          <div className="h-box">
            <PageTitleText
              isNotBold
              title={eventHook?.data ? eventHook.data.name : "행사제품"}
              style={{ marginRight: 15 }}
            />
            <span style={{ fontSize: 15, paddingTop: 1, color: "rgba(0,0,0,0.4)" }}>
              ({eventTermText})
            </span>
          </div>
        </div>
        <div css={container}>
          {eventProducts.data.map(item => (
            <EventProduct product={item} />
          ))}
        </div>
        {/* <ArrowButton
          name={SEE_EVENT_PRODUCT_BUTTON}
          arrowDirection="RIGHT"
          onClick={() => navigate(PAGE.EVENT_PRODUCT)}
          isRed
          style={{ alignSelf: "flex-end" }}
        /> */}
        <Button
          variant="outlined"
          endIcon={<ArrowForwardIosRoundedIcon style={{ fontSize: 15, marginBottom: 4 }} />}
          style={{
            borderRadius: 15, 
            fontFamily: "GMarket Sans",
            fontSize: 15,
            fontWeight: 500,
            backgroundColor: "rgba(0,0,0,0.01)",
          }}
          onClick={() => {
            setTimeout(() => navigate(PAGE.EVENT_PRODUCT), 300)
          }}
        >
          행사제품 전부보기
        </Button>
        {storeHour?.storeHour?.text2 && (
          <SingleLineAnnouncement style={{ alignSelf: "center", marginBottom: 80, marginTop: 45 }}>
            {storeHour.storeHour.text2}
          </SingleLineAnnouncement>
        )}
      </article>
    ) : <div/>
  )
}

export default EventProductSection
