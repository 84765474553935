import React from "react"
import useBanner from "./hook/useBanner"
import useInterval from "./useInterval"
import ImageGallery from "react-image-gallery"
import { css } from "@emotion/react"
import { flexBoxCss } from "../../../styles"
const styles = require("./style.module.scss")

interface JumbotronProps {
  style?: React.CSSProperties
}

const container = css`
  max-width: 984px;
  width: 100%;
  max-height: 438px;
  height: 100%;
  ${flexBoxCss("v", "center", "center")}

  @media (max-width: 450px) {
    max-width: none;
    width: 100vw;
    max-height: none;
  }
`

const desktopContainer = css`
  display: block;
  width: 100%;
  height: 100%;

  @media (max-width: 450px) {
    display: none;
  }
`

const mobileContainer = css`
  display: block;
  width: 100%;
  height: 100%;

  @media (min-width: 450px) {
    display: none;
  }
`

const Jumbotron = ({ style }: JumbotronProps) => {
  const bannerHook = useBanner()

  // useInterval(() => {
  //   bannerHook.nextBanner()
  // }, 7000)

  return (
    <article css={container} style={{...style, marginBottom: bannerHook.data.length!==0 ? 40 : 0}}>
      <div css={desktopContainer}>
        <ImageGallery
          items={bannerHook.data}
          showThumbnails={false}
          showFullscreenButton={false}
          slideInterval={5000}
          autoPlay={true}
          slideDuration={300}
          showPlayButton={false}
        />
      </div>
      <div css={mobileContainer}>
        <ImageGallery
          items={bannerHook.mobileData}
          showThumbnails={false}
          showFullscreenButton={false}
          slideInterval={5000}
          autoPlay={true}
          slideDuration={300}
          showPlayButton={false}
        />
      </div>
      {/* <div className={styles["itemContainer"]}>
        <div className={styles["indicator"]}>
          {bannerHook.data.map((item, index) => (
            <div
              className={styles["indicatorDot"]}
              style={{ opacity: index == bannerHook.index ? 1 : 0.2 }}
              key={index.toString()}
            />
          ))}
        </div>
      </div>
      <img src={bannerHook.selectedBanner?.uri} className={styles["image"]} /> */}
    </article>
  )
}

export default Jumbotron
