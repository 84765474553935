import { css } from "@emotion/react"
import React, { useContext } from "react"
import PageTitleText from "../../../atom/PageTitleText"
import OrderAvailableBadge from "../../../atom/OrderAvailableBadge"
import { globalContext } from "../../../../contexts/GlobalContext"
import moment from "moment"

const container = css`
  width: 50%;
  height: 100%;

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 80px;
  }
`

const descriptionText = css`
  font-size: 15px;
  font-weight: 500;
  font-family: "Noto Sans KR";
  margin-top: 40px;

  @media (max-width: 1024px) {
    font-size: 12px;
    margin-top: 20px;
  }
`

const hourText = css`
  font-size: 24px;
  font-weight: bold;
  color: #b53843;
  margin-top: 10px;

  @media (max-width: 1024px) {
    font-size: 17px;
    margin-top: 10px;
  }
`

const StoreInformationContainerSection = () => {
  const storeHour = useContext(globalContext)?.storeHourHook

  function handleFormatTime(time: string) {
    if(time) {
      const tmp = time.split(":")
      return tmp[0] + ":" + tmp[1]
    }
  }

  return (
    <article css={container}>
      <PageTitleText title={"매장안내"} isNotBold/>
      <div css={descriptionText}>오늘의 배달가능시간</div>
      <div css={hourText}>
        {handleFormatTime(storeHour?.storeHour?.startTime)} - {handleFormatTime(storeHour?.storeHour?.endTime)}
      </div>
      <OrderAvailableBadge
        isAvailable={storeHour?.isDeliverable() || false}
        style={{ marginTop: 35 }}
      />
    </article>
  )
}

export default StoreInformationContainerSection
