import { css } from "@emotion/react"
import React, { useContext, useEffect, useRef, useState } from "react"
import Jumbotron from "../components/molecule/Jumbotron"
import SingleLineAnnouncement from "../components/molecule/SingleLineAnnouncement"
import AnnouncementSection from "../components/organism/AnnouncementSection"
import CategoryProductSection from "../components/organism/CategoryProductSection"
import CategorySection from "../components/organism/CategorySection"
import EventProductSection from "../components/organism/EventProductSection"
import Layout from "../components/organism/Layout"
import SEO from "../components/SEO"
import { globalContext } from "../contexts/GlobalContext"
import IndexContextProvider from "../contexts/IndexContext"
import { PAGE } from "../values"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import CloseIcon from "@material-ui/icons/Close"
const styles = require("./style.module.scss")

const rootStyle = css`
  align-items: center;
`

const IndexPage = () => {
  const categories = useContext(globalContext)?.categoryHook
  const storeHour = useContext(globalContext)?.storeHourHook
  const isFirstRender = useRef<boolean>(true)
  const [nowScrollSectionNumber, setNowScrollSectionNumber] = useState<number>(0)

  useEffect(() => {
    const nowScrollHeightHandler = () => {
      let sectionTabs = document.getElementsByClassName("sectionTab")

      if (sectionTabs.length >= 3) {
        for (let i = 2; i >= 0; i--) {
          let relativeHeight = sectionTabs[i].getBoundingClientRect().top
          if (i === 0) {
            //console.log(relativeHeight)
          }
          if (relativeHeight < 70) {
            setNowScrollSectionNumber(i + 1)
            break
          } else if (i === 0) {
            setNowScrollSectionNumber(0)
          }
        }
      }
    }
    if (isFirstRender.current) {
      isFirstRender.current = false
      window.addEventListener("scroll", nowScrollHeightHandler)
    }

    return () => window.removeEventListener("scroll", nowScrollHeightHandler)
  }, [])

  function handleCategoryProductSectionColor(index: number) {
    switch (index) {
      case 0:
        return "rgb(248, 234, 236)"
      case 1:
        return "rgb(235, 238, 248)"
      default:
        return "rgb(234, 248, 237)"
    }
  }

  const alertDialog = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    useEffect(() => {
      const modalSavedString = localStorage.getItem("TODAY_MODAL_SHOW")
      if(modalSavedString) {
        const modalSaved = JSON.parse(modalSavedString) as {day: number, month: number}
        const today = new Date()
        if(!(today.getDate()===modalSaved.day && today.getMonth()===modalSaved.month)) {
          if (storeHour?.storeHour?.text4 && storeHour.storeHour.text4!=="") {
            setIsOpen(true)
          } 
        }
      } else {
        if (storeHour?.storeHour?.text4 && storeHour.storeHour.text4!=="") {
          setIsOpen(true)
        }
      }

    }, [storeHour?.storeHour?.text4])

    const todayCloseButtonStyle = css`
      font-family: "Noto Sans KR";
      font-size: 14px;
      font-weight: 700;
    `

    const dialogContentTextStyle = css`
      padding-top: 10px;
      font-family: "Noto Sans KR";
      text-align: center;
    `

    const onClickCloseButton = () => {
      setIsOpen(false)
    }

    return (
      <Dialog
        open={isOpen}
        keepMounted
        onClose={onClickCloseButton}
        fullWidth
        PaperProps={{
          style: {
            width: "100%",
            margin: 0,
            borderRadius: 0,
            alignSelf: "flex-end",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 8,
            borderBottom: "1px solid rgba(0,0,0,0.1)",
          }}
        >
          <span css={todayCloseButtonStyle} onClick={() => {
            localStorage.setItem("TODAY_MODAL_SHOW", JSON.stringify({
              day: new Date().getDate(),
              month: new Date().getMonth()
            }))
            onClickCloseButton()
          }}>오늘 하루 보지 않기</span>
          <div style={{maxHeight: 24}} onClick={onClickCloseButton}>
            <CloseIcon/>
          </div>
        </div>
        <DialogContent>
          <p
            css={dialogContentTextStyle}
            style={{
              whiteSpace: "pre-line",
            }}
            dangerouslySetInnerHTML={{ __html: storeHour?.storeHour?.text4 }}
          >
          </p>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Layout nowPage={PAGE.HOME}>
      <IndexContextProvider>
        {alertDialog()}
        <SEO title="홈" />
        <div css={rootStyle} className={"v-box"}>
          <Jumbotron />
          <div className="v-box">
            <EventProductSection />
            {/* 카테고리 랜덤 섹션 */}
            {categories?.randomlyPickedProducts.map((item, index) => (
              <CategoryProductSection
                pickedCategory={item}
                backgroundColor={handleCategoryProductSectionColor(index)}
                key={index.toString()}
                nowScrollSectionNumber={nowScrollSectionNumber}
                index={index}
              />
            ))}
            <CategorySection data={categories?.data || []} />
            <article className={styles["sectionContainer"]} style={{ marginBottom: 0 }}>
              {storeHour?.storeHour?.text3 ? (
                <SingleLineAnnouncement style={{ marginTop: 60, marginBottom: 20 }} isRed>
                  {storeHour.storeHour.text3}
                </SingleLineAnnouncement>
              ) : null}
              <AnnouncementSection />
            </article>
          </div>
        </div>
      </IndexContextProvider>
    </Layout>
  )
}

export default IndexPage
