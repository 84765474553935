import { css } from "@emotion/react"
import React from "react"
import { flexBoxCss } from "../../../styles"
const styles = require("./style.module.scss")

interface SingleLineAnnouncementProps {
  children: string
  style?: React.CSSProperties
  isRed?: boolean
  isThin?: boolean
}

const SingleLineAnnouncement = ({ children, style, isRed, isThin }: SingleLineAnnouncementProps) => {
  const container = css`
    background: ${"linear-gradient(0.75turn, #ce572d, #b53843)"};
    width: 984px;
    text-align: center;
    height: 76px;
    padding-top: 3px;
    font-size: 20px;
    font-weight: 300;
    color: white;
    ${flexBoxCss("v", "center", "center")};
    opacity: 0.9;
    animation-name: leftToRight;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    
    @media (max-width: 1024px) {
      font-size: 15px;
      height: 60px;
      width: 100%;
    }
  `

  return (
    <span style={style} css={container}>
      {children}
    </span>
  )
}

export default SingleLineAnnouncement
